import type { FieldMetadata } from "@conform-to/react";
import { getSelectProps, getTextareaProps, unstable_useControl } from "@conform-to/react";
import { IconCurrencyDollar } from "@tabler/icons-react";
import React, { ChangeEventHandler, useRef, useState } from "react";

import { Input } from "~/components/ui/input";
import { Label } from "~/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "~/components/ui/select";
import { Textarea } from "~/components/ui/textarea";
import { useAutosizeTextArea } from "~/hooks/useAutosizeTextarea";
import { cn } from "~/lib/utils";

interface FormInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "className"> {
  id: string;
  name: string;
  label: string;
  errors: Array<string> | undefined;
  description?: string;
  hideLabel?: boolean;
  isCurrency?: boolean;
}

export function FormInput(props: FormInputProps) {
  const { label, errors, hideLabel, description, isCurrency = false, type: propType, ...rest } = props;
  const hasErrors = errors && errors.length;
  const [type, setType] = useState(propType);

  return (
    <div className="relative">
      <Label
        htmlFor={rest.id}
        className={cn(
          hideLabel ? "sr-only" : "mb-1.5",
          hasErrors && "text-destructive",
          props.disabled && "cursor-not-allowed opacity-50",
        )}
      >
        <span>{label}</span>
        <span
          className={cn(
            "ml-1 inline-block font-normal",
            props.required || hasErrors ? "text-destructive" : "text-muted-foreground",
            !props.required && "text-xs",
          )}
        >
          {props.required ? "*" : "(optional)"}
        </span>
      </Label>
      <Input
        className={cn(
          hasErrors && "border-destructive focus-visible:border-destructive focus-visible:ring-destructive/50",
          isCurrency && "pl-7",
        )}
        onBlur={(e) => {
          if (isCurrency) {
            const value = parseFloat(e.currentTarget.value);
            if (isNaN(value)) {
              e.currentTarget.value = "";
            } else {
              e.currentTarget.value = value.toFixed(2);
            }
          }
          props.onBlur?.(e);
        }}
        aria-describedby={!hasErrors && description ? `${props.id}-description` : props["aria-describedby"]}
        type={type}
        {...rest}
      />
      {isCurrency ? (
        <span className="pointer-events-none absolute left-2 top-[34px] text-muted-foreground">
          <IconCurrencyDollar className="h-4 w-4 text-muted-foreground" strokeWidth={2.5} />
        </span>
      ) : null}
      {propType === "password" ? (
        <button
          type="button"
          className="absolute -top-0.5 right-0 rounded p-2 text-xs text-muted-foreground underline transition focus:outline-none focus-visible:ring focus-visible:ring-primary/50"
          onClick={() => setType((t) => (t === "password" ? "text" : "password"))}
        >
          {type === "password" ? "Show" : "Hide"}
        </button>
      ) : null}
      {description || hasErrors ? (
        <div className="ml-0.5 mt-0.5">
          {!hasErrors ? <FieldDescription id={props.id} description={description} /> : null}
          <FieldErrors id={props.id} errors={errors} />
        </div>
      ) : null}
    </div>
  );
}

export function FormError({ children }: { children: React.ReactNode }) {
  return (
    <p
      aria-live="polite"
      className={cn(
        "rounded-lg border border-destructive bg-destructive/5 p-2 text-xs font-medium text-destructive",
        children ? "not-sr-only" : "sr-only",
      )}
    >
      {children}
    </p>
  );
}

export interface FormSelectProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  meta: FieldMetadata<string | number>;
  label: string;
  placeholder: string;
  control?: ReturnType<typeof unstable_useControl<string>>;
  description?: string;
  required?: boolean;
  options?: Array<{ value: string | number; label: string | JSX.Element }>;
  hideLabel?: boolean;
  divProps?: React.HTMLAttributes<HTMLDivElement>;
  children?: React.ReactNode;
}

export function FormSelect(props: FormSelectProps) {
  const { label, placeholder, options, hideLabel, divProps, meta, control, ...rest } = props;
  const metaProps = getSelectProps(meta);
  const internalControl = unstable_useControl(meta);

  const hasErrors = meta.errors && meta.errors.length;

  const controlToUse = control || internalControl;

  return (
    <div {...divProps} className={cn("relative w-full", divProps?.className)}>
      <Label
        htmlFor={rest.id}
        className={cn(
          hideLabel ? "sr-only" : "mb-1",
          hasErrors && "text-destructive",
          props.disabled && "cursor-not-allowed opacity-50",
        )}
      >
        <span>{label}</span>
        <span
          className={cn(
            "ml-1 inline-block font-normal",
            metaProps.required ? "text-destructive" : "text-xs text-muted-foreground",
          )}
        >
          {metaProps.required ? "*" : "(optional)"}
        </span>
      </Label>
      <Select
        name={meta.name}
        value={controlToUse.value}
        onValueChange={(v) => controlToUse.change(v === "none" ? "" : v)}
        onOpenChange={(open) => {
          if (!open) {
            controlToUse.blur();
          }
        }}
      >
        <SelectTrigger
          {...rest}
          aria-label={placeholder}
          className={cn(
            hasErrors && "border-destructive focus-visible:border-destructive focus-visible:ring-destructive/50",
            !controlToUse.value || (controlToUse.value === "none" && "text-muted-foreground"),
            rest.className,
          )}
        >
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent>
          {options && options.length === 0 ? (
            <SelectItem value="none" disabled>
              No options
            </SelectItem>
          ) : (
            !metaProps.required && (
              <SelectItem
                key={`empty-${metaProps.id}`}
                value="none"
                className="text-muted-foreground/60 focus:text-muted-foreground/60"
              >
                {placeholder}
              </SelectItem>
            )
          )}
          {options
            ? options.map((o) => {
                return (
                  <SelectItem key={o.value} value={o.value.toString()}>
                    {o.label}
                  </SelectItem>
                );
              })
            : props.children}
        </SelectContent>
        {props.description || hasErrors ? (
          <div className="ml-0.5 mt-0.5">
            <FieldDescription id={metaProps.id} description={props.description} />
            <FieldErrors id={metaProps.id} errors={meta.errors} />
          </div>
        ) : null}
      </Select>
    </div>
  );
}

interface FormTextareaProps
  extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, keyof FieldMetadata<string>> {
  meta: FieldMetadata<string>;
  label: string;
  errors: Array<string> | undefined;
  description?: string;
  hideLabel?: boolean;
  autoSize?: boolean;
}

export function FormTextarea(props: FormTextareaProps) {
  const { label, errors, hideLabel, description, className, meta, autoSize, ...rest } = props;
  const hasErrors = errors && errors.length;
  const control = unstable_useControl(meta);
  const ref = useRef<HTMLTextAreaElement>(null);
  const { key, ...metaProps } = getTextareaProps(meta);

  useAutosizeTextArea(autoSize ? ref.current : null, control.value || "");

  return (
    <div className="relative">
      <Label
        htmlFor={metaProps.id}
        className={cn(
          hideLabel ? "sr-only" : "mb-1.5",
          hasErrors && "text-destructive",
          props.disabled && "cursor-not-allowed opacity-50",
        )}
      >
        <span>{label}</span>
        <span
          className={cn(
            "ml-1 inline-block font-normal",
            metaProps.required || hasErrors ? "text-destructive" : "text-muted-foreground",
            !metaProps.required && "text-xs",
          )}
        >
          {metaProps.required ? "*" : "(optional)"}
        </span>
      </Label>
      <Textarea
        key={key}
        ref={ref}
        onFocus={control.focus}
        onBlur={control.blur}
        onChange={control.change as unknown as ChangeEventHandler<HTMLTextAreaElement>}
        className={cn(
          hasErrors && "border-destructive focus-visible:border-destructive focus-visible:ring-destructive/50",
          autoSize && "resize-none",
          className,
        )}
        aria-describedby={!hasErrors && description ? `${metaProps.id}-description` : props["aria-describedby"]}
        rows={autoSize ? 1 : 4}
        {...rest}
        {...metaProps}
      />
      {description || hasErrors ? (
        <div className="ml-0.5 mt-0.5">
          {!hasErrors ? <FieldDescription id={metaProps.id} description={description} /> : null}
          <FieldErrors id={metaProps.id} errors={errors} />
        </div>
      ) : null}
    </div>
  );
}

function FieldErrors({ id, errors }: { id: string; errors?: Array<string> }) {
  if (!errors || !errors.length) {
    return null;
  }

  return (
    <p aria-live="polite" id={`${id}-error`} className="text-xs font-medium text-destructive">
      {errors.map((error) => (
        <span key={error}>{error}</span>
      ))}
    </p>
  );
}

function FieldDescription({ id, description }: { id: string; description?: string }) {
  if (!description) return null;
  return (
    <p id={`${id}-description`} className="text-xs text-muted-foreground">
      {description}
    </p>
  );
}
