import { useForm as useConform } from "@conform-to/react";
import { getZodConstraint, parseWithZod } from "@conform-to/zod";
import { FormOptions } from "node_modules/@conform-to/react/context";
import { z } from "zod";

type PropsWeControl = "shouldValidate" | "shouldRevalidate" | "constraint" | "onValidate";
interface Props<T extends z.ZodTypeAny> extends Omit<FormOptions, "formId" | PropsWeControl> {
  schema: T;
}

export function useForm<T extends z.ZodTypeAny>({ schema, ...rest }: Props<T>) {
  return useConform<z.infer<T>>({
    shouldValidate: "onSubmit",
    shouldRevalidate: "onInput",
    constraint: getZodConstraint(schema),
    onValidate({ formData }) {
      return parseWithZod(formData, { schema });
    },
    ...rest,
  });
}
